import type { InferGetServerSidePropsType } from 'next'
import { DesignCategoryFragment } from '../app/data/fragments/DesignCategoryFragment'
import { HeaderFragment } from '../app/data/fragments/HeaderFragment'
import { ImageListFragment } from '../app/data/fragments/ImageListFragment'
import { ProfileFragment } from '../app/data/fragments/ProfileFragment'
import { SeoFragment } from '../app/data/fragments/SeoFragment'
import { Container } from '../components/Container'
import { Header } from '../components/Header'
import { HomePage } from '../components/HomePage'
import { Seo } from '../components/Seo'
import { Spacer } from '../components/Spacer'
import { One, OrderDirection, Thunder } from '../generated/content'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	return (
		<>
			<Seo seo={props.homepageData.getHomePage?.seo} />
			<div>
				<Container size="wide" disableGutters>
					<Header {...props.headerData} />
				</Container>
				<Container>
					<HomePage {...props.homepageData} />
				</Container>
				<Spacer initial={20} break560={50} />
			</div>
		</>
	)
}

export const getServerSideProps = async () => {
	const content = getZeusConnection(Thunder, 'content')

	const homepageData = await content.query({
		getHomePage: [
			{ by: { unique: One.One } },
			{
				heroImages: [{}, ImageListFragment()],
				profile: [{}, ProfileFragment()],
				seo: [{}, SeoFragment()],
			},
		],
		listDesignCategory: [
			{
				orderBy: [{ order: OrderDirection.asc }],
				filter: {
					designs: {
						id: {
							null: false,
						},
					},
				},
			},
			DesignCategoryFragment(),
		],
	})

	const { getHeader: headerData } = await content.query({
		getHeader: [
			{
				by: { unique: One.One },
			},
			HeaderFragment(),
		],
	})

	return {
		props: {
			homepageData,
			headerData,
			serverTime: new Date().toString(),
		},
	}
}
