import type { CommonPageProps } from '../pages/index'
import { DesignCategories } from './DesignCategories'
import { Hero } from './Hero'
import style from './HomePage.module.sass'
import { Profile } from './Profile'

export type HomePageProps = Pick<
	CommonPageProps['homepageData'],
	'getHomePage' | 'listDesignCategory'
>

export const HomePage: React.FunctionComponent<HomePageProps> = ({
	getHomePage,
	listDesignCategory,
}) => {
	return (
		<div className={style.wrapper}>
			{getHomePage?.heroImages && <Hero backgrounds={getHomePage.heroImages} />}
			{getHomePage?.profile && <Profile {...getHomePage.profile} />}
			<DesignCategories items={listDesignCategory} />
		</div>
	)
}
