import Head from 'next/head'
import type { FunctionComponent } from 'react'
import type { SeoResult } from '../app/data/fragments/SeoFragment'

interface SeoProps {
	seo?: SeoResult
}

export const Seo: FunctionComponent<SeoProps> = ({
	seo: { title, description, ogTitle, ogDescription, ogImage } = {},
}) => {
	return (
		<Head>
			<title>{title}</title>
			{description && <meta name="description" content={description} />}
			<meta property="og:locale" content="cs_CZ" />
			{ogTitle && <meta property="og:title" content={ogTitle} />}
			{ogDescription && <meta property="og:description" content={ogDescription} />}
			{ogImage && (
				<>
					<meta property="og:image" content={ogImage.url} />
					{ogImage.alt && <meta property="og:image:alt" content={ogImage.alt} />}
					{ogImage.width && <meta property="og:image:width" content={`${ogImage.width}`} />}
					{ogImage.height && <meta property="og:image:height" content={`${ogImage.height}`} />}
				</>
			)}
		</Head>
	)
}
