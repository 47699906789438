import type { DesignCategoryResult } from '../app/data/fragments/DesignCategoryFragment'
import style from './DesignCategories.module.sass'
import { DesignTiles } from './DesignTiles'

type DesignCategories = {
	items: DesignCategoryResult[]
}

export const DesignCategories: React.FunctionComponent<DesignCategories> = ({ items }) => {
	return (
		<div className={style.wrapper}>
			{items.map((item) => (
				<div key={item.id} className={style.item}>
					<DesignTiles title={item.title} designs={item.designs} />
				</div>
			))}
		</div>
	)
}
