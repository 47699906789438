import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import type { AsyncReturnType } from 'type-fest'
import type { DesignResult } from '../app/data/fragments/DesignFragment'
import { DesignTile } from './DesignTile'
import style from './DesignTiles.module.sass'
import { Title } from './Title'

const loadLightbox = () => import('@mangoweb/fslightbox').then((module) => module.lightbox)

type Lightbox = AsyncReturnType<typeof loadLightbox>

type DesignTiles = {
	title: string
	designs: DesignResult[]
}

export const DesignTiles: React.FunctionComponent<DesignTiles> = ({ title, designs }) => {
	const [lightbox, setLightbox] = useState<null | Lightbox>(null)

	useEffect(() => {
		loadLightbox().then((lightbox) => setLightbox(() => lightbox))
	}, [])

	const gallery = useMemo(
		() =>
			lightbox &&
			lightbox(
				designs.map((item) => ({
					source: item.image?.url ?? '',
					type: 'image',
					caption: item.description,
				}))
			),
		[designs, lightbox]
	)
	return (
		<div className={style.wrapper}>
			{title && <Title>{title}</Title>}
			<div className={style.tiles}>
				{designs &&
					designs.map((tile, index) => (
						<div
							key={tile.id}
							className={clsx(style.item, tile.imageDisplayOption && style['view-wide'])}>
							{tile.descriptionDisplayOptions != 'none' && (
								<div
									className={clsx(
										style.description,
										style[`view-${tile.descriptionDisplayOptions}`]
									)}>
									{tile.description}
								</div>
							)}
							<button
								type="button"
								onClick={() => gallery?.open(index)}
								key={tile.id}
								className={style.tile}
								aria-label={tile.description ?? tile.image?.alt ?? ''}>
								<DesignTile {...tile} />
							</button>
						</div>
					))}
			</div>
		</div>
	)
}
