import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import type { ImageListResult } from '../app/data/fragments/ImageListFragment'
import style from './Hero.module.sass'

type HeroProps = {
	backgrounds: ImageListResult
}

export const Hero: React.FunctionComponent<HeroProps> = ({ backgrounds }) => {
	const [activeIndex, setActiveIndex] = useState(0)
	useEffect(() => {
		let localActiveindex = 0
		const timer = setInterval(() => {
			if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
				localActiveindex++
				setActiveIndex(localActiveindex % backgrounds.items.length)
			}
		}, 150)

		return () => {
			clearInterval(timer)
		}
	}, [])
	return (
		<div className={style.wrapper}>
			<div className={style.backgrounds}>
				{backgrounds.items.map(
					(item, i) =>
						item.image && (
							<div key={item.id} className={clsx(style.item, i === activeIndex && style.isActive)}>
								<Image
									src={item.image.url}
									alt={item.image.alt ?? ''}
									layout="fill"
									objectFit="cover"
									sizes="(max-width: 1000px) 100vw, 960px"
								/>
							</div>
						)
				)}
			</div>
		</div>
	)
}
