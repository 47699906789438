import Image from 'next/image'
import type { CommonPageProps } from '../pages/index'
import { Container } from './Container'
import style from './Header.module.sass'

export type HeaderProps = NonNullable<CommonPageProps['headerData']>

export const Header: React.FunctionComponent<HeaderProps> = ({ title, logo, background }) => {
	return (
		<div className={style.wrapper}>
			<Container>
				<div className={style.content}>
					{logo && logo.url && (
						<div className={style.logo}>
							<Image src={logo.url} width={logo.width} height={logo.height} alt={logo.alt ?? ''} />
						</div>
					)}
					{title && <div className={style.title}>{title}</div>}
				</div>
			</Container>
			<div className={style.background}>
				{background && background.url && (
					<Image src={background.url} layout="fill" objectFit="cover" />
				)}
			</div>
		</div>
	)
}
