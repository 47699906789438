import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import type { ImageResult } from '../app/data/fragments/ImageFragment'
import style from './Profile.module.sass'
import { Title } from './Title'

type ProfileProps = {
	image?: ImageResult
	title?: string
	text?: string
}

export const Profile: React.FunctionComponent<ProfileProps> = ({ image, title, text }) => {
	return (
		<div className={style.wrapper}>
			{title && <Title>{title}</Title>}
			<div className={style.content}>
				<div className={style.image}>
					{image && image.url && (
						<Image
							src={image.url}
							width={image.width}
							height={image.height}
							alt={image.alt ?? ''}
							layout="responsive"
							sizes="(max-width: 560px) 100vw, (max-width: 768) 320px, (max-width: 992) 450px, 350px"
						/>
					)}
				</div>
				<div className={style.text}>{text && <RichTextRenderer source={text} />}</div>
			</div>
		</div>
	)
}
