import Image from 'next/image'
import type { DesignResult } from '../app/data/fragments/DesignFragment'
import style from './DesignTile.module.sass'

type DesignTile = DesignResult

export const DesignTile: React.FunctionComponent<DesignTile> = ({ title, image }) => {
	return (
		<div className={style.wrapper}>
			{image && image.url && (
				<Image
					src={image.url}
					width={image.width}
					height={image.height}
					alt={image.alt ?? ''}
					layout="responsive"
					sizes="(max-width: 560px) 100vw, (max-width: 768) 320px, (max-width: 992) 450px, 350px"
				/>
			)}

			<div className={style.hover}>{title && <div className={style.title}>{title}</div>}</div>
		</div>
	)
}
